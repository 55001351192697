import store from '@/store';
import { refactorSocialUrl } from '../../helpers/utils';

export default function () {
  const routes = [];

  if (store.state.appConfig.loginRedirectUrl) {
    routes.push(
      {
        path:      '/',
        name:      'Login',
        meta:      {
          isRedirectLoggedIn: true,
        },
        component: () => import(/* webpackChunkName: "app-user-auth-white-label" */ '@/views/auth/WhiteLabelLogin.vue'),
      },
      {
        path:      '/logout',
        name:      'Logout',
        meta:      {
          isRedirectLoggedIn: true,
        },
        component: () => import(/* webpackChunkName: "app-user-auth-white-label" */ '@/views/auth/children/Logout.vue'),
      },
    );
  } else {
    routes.push(
      {
        path:      '/',
        component: () => import(/* webpackChunkName: "app-user-auth" */ '@/views/auth/Index.vue'),
        meta:      {
          isRedirectLoggedIn: true,
        },
        children: [
          {
            path:      '',
            name:      'Login',
            component: () => import(/* webpackChunkName: "app-user-auth" */ '@/views/auth/children/Login.vue'),
          },
          {
            path:      'forgot-password',
            name:      'ForgotPassword',
            component: () => import(/* webpackChunkName: "app-user-auth" */ '@/views/auth/children/ForgotPassword.vue'),
          },
          {
            path:        'reset-password',
            name:        'ResetPassword',
            component:   () => import(/* webpackChunkName: "app-user-auth" */ '@/views/auth/children/ResetPassword.vue'),
            beforeEnter: (to, from, next) => {
              // this is used to hide user details from displaying on the URL query string
              if (to.query.email) {
                const params = { ...to.query };
                delete (params.next);
                next({
                  name:   to.name,
                  params: params,
                  query:  {
                    successNext: to.query.successNext,
                  },
                });
              }
              next();
            },
          },
          {
            path:      'register',
            name:      'Register',
            component: () => import(/* webpackChunkName: "app-user-auth" */ '@/views/auth/children/Register.vue'),
          },
          {
            path:        'verify',
            name:        'Verify',
            component:   () => import(/* webpackChunkName: "app-user-auth" */ '@/views/auth/children/Verify.vue'),
            beforeEnter: (to, from, next) => {
              // this is used to hide user details from displaying on the URL query string
              if (to.query.userId) {
                const params = { ...to.query };
                delete (params.next);
                next({
                  name:   to.name,
                  params: params,
                  query:  {
                    successNext: to.query.successNext,
                  },
                });
              }
              next();
            },
          },
        ],
      },
      {
        path:        '/social/:provider',
        name:        'social-provider',
        beforeEnter: async (to, from, next) => {
          const redirectUrl = refactorSocialUrl(to, new URL(`${process.env.VUE_APP_WEB_URL}/socialite/${to.params.provider}/redirect`));

          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            next('login');
          }
        },
      },
    );
  }

  return routes;
}
